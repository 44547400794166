import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getCountryList, getTransferTypes } from "../../../services/fetchers";
import { initialLocalData, initialPayoneerData, initialSwiftData, initialWMZorUSDTdata } from "../../../../../data/initialData";
import SwiftPaymentFields from "./components/SwiftPaymentFields";
import PayoneerPaymentFields from "./components/PayoneerPaymentFields";
import LocalPaymentFields from "./components/LocalPaymentFields";
import OnlineWalletPaymentFields from "./components/OnlineWalletPaymentFields";
import SelectInputField from "../../../../../UI/Inputs/SelectInputField";
import { handleChangeField } from "../../../services/helpers";

const PaymentDetailsForm = React.memo(({ client, paymentDetails, setPaymentDetails, countries, setCountries, currencies, readOnly = false }: any) => {
    const [firstLoad, setFirstLoad] = useState(true);
    const [transferTypes, setTransferTypes] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (countries.length === 0) {
            (async () => setCountries(await getCountryList()))();
        }
    }, [countries]);

    useEffect(() => {
        (async () => setTransferTypes(await getTransferTypes()))();
    }, []);

    useEffect(() => {
        let initialData;
        if (transferTypes.length > 0) {
            if (firstLoad) {
                setFirstLoad(false);
            } else {
                switch (paymentDetails.type) {
                    case "SWIFT from Payoneer":
                    case "SWIFT from KZ":
                    case "SWIFT from Airwallex":
                    case "SWIFT from Aspire":
                    case "SWIFT from Revolut":
                        initialData = initialSwiftData;
                        break;
                    case "Payoneer to Payoneer":
                        initialData = initialPayoneerData;
                        break;
                    case "Local from Airwallex":
                    case "Local from Aspire":
                    case "Local From Revolut":
                        initialData = initialLocalData;
                        break;
                    case "USDT":
                    case "WMZ":
                        initialData = initialWMZorUSDTdata;
                        break;
                    default:
                        break;
                }
                setPaymentDetails({
                    ...paymentDetails,
                    ...initialData,
                });
            }
        }
    }, [paymentDetails.type]);
    const renderFields = useMemo(() => {
        switch (paymentDetails.type) {
            case "SWIFT from Payoneer":
            case "SWIFT from KZ":
            case "SWIFT from Airwallex":
            case "SWIFT from Aspire":
            case "SWIFT from Revolut":
                return <SwiftPaymentFields readOnly={readOnly} client={client} paymentDetails={paymentDetails} setPaymentDetails={setPaymentDetails} countries={countries} />;
            case "Payoneer to Payoneer":
                return <PayoneerPaymentFields readOnly={readOnly} paymentDetails={paymentDetails} setPaymentDetails={setPaymentDetails} />;
            case "Local from Airwallex":
            case "Local from Aspire":
            case "Local From Revolut":
                return <LocalPaymentFields readOnly={readOnly} client={client} paymentDetails={paymentDetails} setPaymentDetails={setPaymentDetails} countries={countries} />;
            case "USDT":
            case "WMZ":
                return <OnlineWalletPaymentFields readOnly={readOnly} paymentDetails={paymentDetails} setPaymentDetails={setPaymentDetails} />;
            default:
                return null;
        }
    }, [paymentDetails.type, client.companyType, paymentDetails, countries]);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    gap: "1rem",
                    flexWrap: "wrap",
                    mb: "1rem",
                }}
            >
                <SelectInputField
                    readOnly={readOnly}
                    value={paymentDetails.contractCurrency}
                    label={t("forms.contract.currency")}
                    items={currencies}
                    onChange={(value) => handleChangeField("contractCurrency", value, paymentDetails, setPaymentDetails)}
                />
                <SelectInputField
                    readOnly={readOnly}
                    value={paymentDetails.type}
                    label={t("forms.payment.transfer_method_label")}
                    items={transferTypes}
                    onChange={(value) => handleChangeField("type", value, paymentDetails, setPaymentDetails)}
                />
            </Box>
            {renderFields}
        </>
    );
});

export default PaymentDetailsForm;
